import React from "react"
import { useIntl } from "react-intl"

import { SerialFilter } from ".."
import MultipleSelector, { Option } from "@/components/ui/multiple-selector"

interface Props {
    data: Option[]
    filters: SerialFilter

    setFilters: React.Dispatch<React.SetStateAction<SerialFilter>>
}
const BatchNo: React.FC<Props> = ({ data, filters, setFilters }) => {
    const intl = useIntl()

    return (
        <div className="flex w-full flex-col px-1">
            <p>{intl.formatMessage({ id: "batch_no" })}</p>
            <div className="flex flex-row gap-4 py-2">
                <MultipleSelector
                    key={JSON.stringify(data)}
                    value={
                        filters.batch_no?.map((batchNo) => ({
                            label: batchNo === "null" ? intl.formatMessage({ id: "no_batch_no" }) : batchNo,
                            value: batchNo,
                        })) ?? undefined
                    }
                    onSearchSync={(value: string) => {
                        return data.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()))
                    }}
                    onChange={(values: Option[]) => {
                        setFilters((prev) => {
                            const updatedBatchNoList = values.map((value) => value.value)
                            return {
                                ...prev,
                                batch_no: updatedBatchNoList.length > 0 ? updatedBatchNoList : null,
                                mfg_date: null, // Reset mfg_date when batch_no is updated
                                exp_date: null, // Reset exp_date when batch_no is updated
                            }
                        })
                    }}
                    options={data}
                    placeholder={intl.formatMessage({ id: "select_batch_no" })}
                    emptyIndicator={<p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">{intl.formatMessage({ id: "no_results" })}</p>}
                    badgeClassName="rounded-sm p-1"
                />
            </div>
        </div>
    )
}

export default BatchNo
