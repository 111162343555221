"use client"

import { cn } from "@/lib/utils"
import { debounce } from "lodash"
import { useIntl } from "react-intl"
import { RefreshCcw, Search } from "lucide-react"
import React, { ReactElement, useState } from "react"

import InventoryNode from "./inventory-node"
import { Input } from "@/components/ui/input"
import Loader2 from "@/components/ui/loader2"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Drawer, DrawerContent, DrawerFooter, DrawerPositionEnum, DrawerTitle } from "@/components/ui/drawer"

import { MergedInventory } from "@/types/Inventory"

import usePutawayState from "@/api/putaway/usePutawayState"
import useSetPutawayState from "@/api/putaway/useSetPutawayState"
import usePutawayInventories from "@/hooks/use-putaway-inventories"
import useSetLocationLayout from "@/api/location/useSetLocationLayout"

const SelectItemDrawer: React.FC = (): ReactElement => {
    const intl = useIntl()

    const [search, setSearch] = useState<string>()

    const {
        data: { openAddItemDrawer },
    } = usePutawayState()
    const { mutate: mutatePutawayState } = useSetPutawayState()
    const { mutate: mutateLocationLayout } = useSetLocationLayout()

    const {
        data: putawayInventories,
        isFetching,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        refetch,
    } = usePutawayInventories({
        enableHook: openAddItemDrawer,
        param: {
            item_code: search,
        },
    })
    const inventories = putawayInventories?.pages.flatMap((page) => page.data) ?? []

    const onCloseItemForm = () => {
        mutateLocationLayout({
            isOpen: false,
            selectedId: 0,
            selectedStorageBinId: 0,
            selectableStorageBinFn: undefined,
        })
        mutatePutawayState({
            openAddItemDrawer: false,
            selectedInventory: undefined,
            selectedTransaction: undefined,
            putawayDetail: {
                data: undefined,
                editMode: false,
            },
        })

        setSearch("")
    }

    const debouncedSearch = debounce((value: string) => {
        setSearch(value)
    }, 500)

    return (
        <Drawer shouldScaleBackground direction="left" dismissible={false} open={openAddItemDrawer} modal={false}>
            <DrawerContent hideHandle position={DrawerPositionEnum.LEFT} className={cn("h-full w-1/3 min-w-[500px]")}>
                <div className="space-y-4">
                    <DrawerTitle>{intl.formatMessage({ id: "items_to_putaway" })}</DrawerTitle>
                    <p className="text-muted-foreground">{intl.formatMessage({ id: "items_to_putaway_desc" })}</p>
                    <div className="flex flex-row items-center justify-between">
                        <Input
                            prefix={<Search className="h-4 w-4 text-muted-foreground" />}
                            placeholder={`${intl.formatMessage({ id: "search" })} ${intl.formatMessage({ id: "item" })}`}
                            onChange={(e) => {
                                debouncedSearch(e.target.value)
                            }}
                            className="flex-1"
                        />
                        <Button onClick={() => refetch()}>
                            <RefreshCcw />
                        </Button>
                    </div>

                    {isFetching && !isFetchingNextPage ? (
                        <div className="flex items-center justify-center">
                            <Loader2 className="h-16 w-16" />
                        </div>
                    ) : (
                        <ScrollArea className="h-[75vh] pr-4">
                            <div className="flex flex-col gap-2">
                                {inventories.map((inventory: MergedInventory, index: number) => (
                                    <InventoryNode key={inventory.merged_id} index={index} inventory={inventory} inventoriesLength={inventories.length} />
                                ))}
                                {hasNextPage && (
                                    <Button className="h-max rounded-sm py-1" onClick={() => fetchNextPage()}>
                                        {isFetchingNextPage ? <Loader2 className="h-4 w-4" /> : intl.formatMessage({ id: "load_more" })}
                                    </Button>
                                )}
                            </div>
                        </ScrollArea>
                    )}
                </div>
                <DrawerFooter>
                    <Button variant="secondary" onClick={onCloseItemForm}>
                        {intl.formatMessage({ id: "close" })}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default SelectItemDrawer
