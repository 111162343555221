import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import Filters from "./filters"
import { Serial } from "@/types/General"

import SerialNosSelect from "./serial-nos-select"
import { Separator } from "@/components/ui/separator"

interface Props {
    data: Serial[]
    selectedSerials: Serial[]
    setSelectedSerials: React.Dispatch<React.SetStateAction<Serial[]>>
}
export interface SerialFilter {
    batch_no: string[] | null
    mfg_date: string[] | null
    exp_date: string[] | null
}

const BatchSerialNoField: React.FC<Props> = ({ data, selectedSerials, setSelectedSerials }) => {
    const form = useFormContext()

    const [filters, setFilters] = React.useState<SerialFilter>({
        batch_no: null,
        mfg_date: null,
        exp_date: null,
    })

    useEffect(() => {
        setSelectedSerials([])
    }, [filters])

    const applyFilters = (serial: Serial) => {
        const matchesBatchNo = !filters.batch_no || (serial.batch_no && filters.batch_no.includes(serial.batch_no))
        const matchesMfgDate = !filters.mfg_date || (serial.mfg_date && filters.mfg_date.includes(serial.mfg_date))
        const matchesExpDate = !filters.exp_date || (serial.exp_date && filters.exp_date.includes(serial.exp_date))

        return matchesBatchNo && matchesMfgDate && matchesExpDate
    }

    return (
        <div className="flex w-full flex-col gap-1 rounded-sm border p-2">
            <Filters data={data} filters={filters} setFilters={setFilters} />
            <Separator className="mb-1" />
            <SerialNosSelect data={data.filter(applyFilters)} selectedSerials={selectedSerials} setSelectedSerials={setSelectedSerials} filters={filters} />

            {form.formState.errors.serial_nos && <span className="text-sm text-red-500">{String(form.formState.errors.serial_nos.message)}</span>}
        </div>
    )
}
export default BatchSerialNoField
