import { GeneralStatusEnum } from "./GeneralStatus"

export type SalesOrderStatus =
    | GeneralStatusEnum.PENDING
    | GeneralStatusEnum.VOID
    | GeneralStatusEnum.GENERATED
    | GeneralStatusEnum.PICKING
    | GeneralStatusEnum.READY_TO_PACK
    | GeneralStatusEnum.PACKING
    | GeneralStatusEnum.PACKED
    | GeneralStatusEnum.READY_TO_SHIP
    | GeneralStatusEnum.SHIPPED
    | GeneralStatusEnum.PARTIAL_COMPLETED
    | GeneralStatusEnum.COMPLETED

export const SalesOrderStatusLabel = {
    [GeneralStatusEnum.PENDING]: "Pending",
    [GeneralStatusEnum.VOID]: "Void",
    [GeneralStatusEnum.GENERATED]: "Generated",
    [GeneralStatusEnum.PICKING]: "Picking",
    [GeneralStatusEnum.READY_TO_PACK]: "Ready to Pack",
    [GeneralStatusEnum.PACKING]: "Packing",
    [GeneralStatusEnum.PACKED]: "Packed",
    [GeneralStatusEnum.READY_TO_SHIP]: "Ready to Ship",
    [GeneralStatusEnum.SHIPPED]: "Shipped",
    [GeneralStatusEnum.PARTIAL_COMPLETED]: "Partially Completed",
    [GeneralStatusEnum.COMPLETED]: "Completed",
}

export const SalesOrderStatusSequence = [
    {
        status: GeneralStatusEnum.PENDING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.GENERATED,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PICKING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.READY_TO_PACK,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PACKING,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PACKED,
        optional: false,
    },
    {
        status: GeneralStatusEnum.READY_TO_SHIP,
        optional: false,
    },
    {
        status: GeneralStatusEnum.SHIPPED,
        optional: false,
    },
    {
        status: GeneralStatusEnum.PARTIAL_COMPLETED,
        optional: false,
    },
    {
        status: GeneralStatusEnum.COMPLETED,
        optional: false,
    },
]

export const SalesOrderStatusOptions = [
    { value: GeneralStatusEnum.PENDING, label: GeneralStatusEnum.PENDING },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusEnum.VOID },
    { value: GeneralStatusEnum.GENERATED, label: GeneralStatusEnum.GENERATED },
    { value: GeneralStatusEnum.PICKING, label: GeneralStatusEnum.PICKING },
    { value: GeneralStatusEnum.READY_TO_PACK, label: GeneralStatusEnum.READY_TO_PACK },
    { value: GeneralStatusEnum.PACKING, label: GeneralStatusEnum.PACKING },
    { value: GeneralStatusEnum.PACKED, label: GeneralStatusEnum.PACKED },
    { value: GeneralStatusEnum.READY_TO_SHIP, label: GeneralStatusEnum.READY_TO_SHIP },
    { value: GeneralStatusEnum.SHIPPED, label: GeneralStatusEnum.SHIPPED },
    { value: GeneralStatusEnum.PARTIAL_COMPLETED, label: GeneralStatusEnum.PARTIAL_COMPLETED },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusEnum.COMPLETED },
]

export const SalesOrderStatusSelectOptions = [
    { value: GeneralStatusEnum.PENDING, label: SalesOrderStatusLabel[GeneralStatusEnum.PENDING] },
    { value: GeneralStatusEnum.VOID, label: SalesOrderStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.GENERATED, label: SalesOrderStatusLabel[GeneralStatusEnum.GENERATED] },
    { value: GeneralStatusEnum.PICKING, label: SalesOrderStatusLabel[GeneralStatusEnum.PICKING] },
    { value: GeneralStatusEnum.READY_TO_PACK, label: SalesOrderStatusLabel[GeneralStatusEnum.READY_TO_PACK] },
    { value: GeneralStatusEnum.PACKING, label: SalesOrderStatusLabel[GeneralStatusEnum.PACKING] },
    { value: GeneralStatusEnum.PACKED, label: SalesOrderStatusLabel[GeneralStatusEnum.PACKED] },
    { value: GeneralStatusEnum.READY_TO_SHIP, label: SalesOrderStatusLabel[GeneralStatusEnum.READY_TO_SHIP] },
    { value: GeneralStatusEnum.SHIPPED, label: SalesOrderStatusLabel[GeneralStatusEnum.SHIPPED] },
    { value: GeneralStatusEnum.PARTIAL_COMPLETED, label: SalesOrderStatusLabel[GeneralStatusEnum.PARTIAL_COMPLETED] },
    { value: GeneralStatusEnum.COMPLETED, label: SalesOrderStatusLabel[GeneralStatusEnum.COMPLETED] },
]
