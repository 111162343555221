import React from "react"
import { useIntl } from "react-intl"

import { SerialFilter } from ".."

import MultipleSelector, { Option } from "@/components/ui/multiple-selector"

interface Props {
    data: Option[]
    filters: SerialFilter
    setFilters: React.Dispatch<React.SetStateAction<SerialFilter>>
}

const MfgDate: React.FC<Props> = ({ data, filters, setFilters }) => {
    const intl = useIntl()

    return (
        <div className="flex w-full flex-col px-1">
            <p>{intl.formatMessage({ id: "mfg_date" })}</p>
            <div className="flex flex-row gap-4 py-2">
                <MultipleSelector
                    key={JSON.stringify(data)}
                    value={
                        filters.mfg_date?.map((mfgDate) => ({
                            label: mfgDate === "null" ? intl.formatMessage({ id: "no_mfg_date" }) : mfgDate,
                            value: mfgDate,
                        })) ?? undefined
                    }
                    onSearchSync={(value: string) => {
                        return data.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()))
                    }}
                    onChange={(values: Option[]) => {
                        setFilters((prev) => {
                            const updatedExpDateList = values.map((value) => value.value)
                            return {
                                ...prev,
                                mfg_date: updatedExpDateList.length > 0 ? updatedExpDateList : null,
                                exp_date: null, // Reset exp_date when mfg_date changes
                            }
                        })
                    }}
                    options={data}
                    placeholder={intl.formatMessage({ id: "select_mfg_date" })}
                    emptyIndicator={<p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">{intl.formatMessage({ id: "no_results" })}</p>}
                    badgeClassName="rounded-sm p-1"
                />
            </div>
        </div>
    )
}

export default MfgDate
