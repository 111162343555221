import useFetchGroupedInventoryTransactions from "@/api/grouped-inventory/useFetchGroupedInventoryTransactions"
import usePutawayState from "@/api/putaway/usePutawayState"
import { GeneralEnum } from "@/constants/General"
import { QueryProps } from "@/lib/ApiService"
import { PutawayInventory } from "@/types/Inventory"

interface Props extends QueryProps {
    param?: any
    inventories?: PutawayInventory[]
}

const usePutawayInventories = ({ inventories, enableHook, param }: Props = {}) => {
    const { data: putawayState } = usePutawayState()

    const fetchGroupedInventories = useFetchGroupedInventoryTransactions({
        enableHook: !!!inventories && enableHook,
        param: {
            storage_bin_id: GeneralEnum.NULL,
            ...(putawayState?.putawayId && {
                putaway_id: putawayState.putawayId,
            }),
            ...(putawayState?.selectedLocation && {
                location_id: putawayState.selectedLocation.id,
            }),
            ...param,
        },
    })

    return fetchGroupedInventories
}

export default usePutawayInventories
