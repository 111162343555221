import { useQuery } from "@tanstack/react-query"
import ApiService, { QueryProps } from "@/lib/ApiService"
import { useSession } from "next-auth/react"
import { PutawayDetail } from "@/types/PutawayDetail"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ enableHook = true, id, param }: QueryProps = {}) => {
    const { data: session, status } = useSession()

    const queryParams = ApiService.toQueryString(param)

    return useQuery({
        queryKey: param ? ["fetch-putaway-details", id, param] : ["fetch-putaway-details", id],
        queryFn: async () => {
            try {
                if (status === "authenticated") {
                    const url = param ? `putaway/${id}/details?${param && queryParams}` : `putaway/${id}/details`
                    const result = await ApiService.getApi(url, session!.token)

                    if (result.isSuccess) {
                        return result.data
                    }
                }

                return {
                    isSuccess: false,
                    message: "Unauthorized",
                }
            } catch (error) {
                return {
                    isSuccess: false,
                    message: (error as Error).message,
                }
            }
        },
        enabled: enableHook && status === "authenticated",
        refetchOnWindowFocus: "always",
    })
}
