import React, { useEffect } from "react"
import { uniq } from "lodash"

import { SerialFilter } from ".."
import { Serial } from "@/types/General"

import BatchNo from "./batch-no"
import MfgDate from "./mfg-date"
import ExpDate from "./exp-date"
import { useIntl } from "react-intl"
import { StringOption } from "@/types"

interface Props {
    data: Serial[]
    filters: SerialFilter

    setFilters: React.Dispatch<React.SetStateAction<SerialFilter>>
}

const Filters: React.FC<Props> = ({ data, filters, setFilters }) => {
    const intl = useIntl()

    const [expData, setExpData] = React.useState<StringOption[]>([])
    const [mfgData, setMfgData] = React.useState<StringOption[]>([])

    useEffect(() => {
        const newMfgData = data
            ? uniq(
                  (filters.batch_no && filters.batch_no?.length > 0
                      ? data.filter((serial) => filters.batch_no!.includes(serial.batch_no)) // Filter based on batch_no
                      : data
                  ) // If filters.batch_no is null or empty, return all data
                      .map((serial) => serial.mfg_date)
              )
            : [] // Return an empty array if data is falsy

        const newExpData = data
            ? uniq(
                  data
                      .filter((serial) => {
                          // Filter based on batch_no if it's selected
                          const batchNoMatches = !filters.batch_no || filters.batch_no.length === 0 || filters.batch_no.includes(serial.batch_no)
                          // Filter based on mfg_date if it's selected
                          const mfgDateMatches = !filters.mfg_date || filters.mfg_date.length === 0 || filters.mfg_date.includes(serial.mfg_date)

                          return batchNoMatches && mfgDateMatches // Return true only if both filters match
                      })
                      .map((serial) => serial.exp_date)
              )
            : []

        setMfgData(newMfgData.map((mfgDate) => ({ label: mfgDate === "null" ? intl.formatMessage({ id: "no_mfg_date" }) : mfgDate, value: mfgDate })))
        setExpData(newExpData.map((expDate) => ({ label: expDate === "null" ? intl.formatMessage({ id: "no_exp_date" }) : expDate, value: expDate })))
    }, [data, filters])

    return (
        <>
            <BatchNo
                data={
                    data
                        ? uniq(data.map((serial) => serial.batch_no)).map((option) => ({
                              label: option === "null" ? intl.formatMessage({ id: "no_batch_no" }) : option,
                              value: option,
                          }))
                        : []
                }
                filters={filters}
                setFilters={setFilters}
            />
            <MfgDate data={mfgData} filters={filters} setFilters={setFilters} />
            <ExpDate data={expData} filters={filters} setFilters={setFilters} />
        </>
    )
}

export default Filters
