import React from "react"
import { uniqBy } from "lodash"
import { useIntl } from "react-intl"

import { cn } from "@/lib/utils"
import { Serial } from "@/types/General"

import { Button } from "@/components/ui/button"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { Input } from "@/components/ui/input"
import { Search, X } from "lucide-react"

interface Props {
    data: Serial[]
    selectedSerials: Serial[]
    filters: {
        batch_no: string[] | null
        mfg_date: string[] | null
        exp_date: string[] | null
    }

    setSelectedSerials: React.Dispatch<React.SetStateAction<Serial[]>>
}

const SerialNosSelect: React.FC<Props> = ({ data, filters, selectedSerials, setSelectedSerials }) => {
    const intl = useIntl()

    const [search, setSearch] = React.useState<string>("")

    const filteredData = data.filter((serial) => serial.serial_no.toLowerCase().includes(search) || serial.batch_no.toLowerCase().includes(search))

    const applyFilters = (serial: Serial) => {
        const matchesBatchNo = !filters.batch_no || (serial.batch_no && filters.batch_no.includes(serial.batch_no))
        const matchesMfgDate = !filters.mfg_date || (serial.mfg_date && filters.mfg_date.includes(serial.mfg_date))
        const matchesExpDate = !filters.exp_date || (serial.exp_date && filters.exp_date.includes(serial.exp_date))

        return matchesBatchNo && matchesMfgDate && matchesExpDate
    }

    const isAllSelected = () => {
        const filteredData = data.filter(applyFilters)

        return filteredData.every((serial) =>
            selectedSerials.some(
                (selected) =>
                    selected.batch_no === serial.batch_no &&
                    selected.mfg_date === serial.mfg_date &&
                    selected.exp_date === serial.exp_date &&
                    selected.serial_no === serial.serial_no
            )
        )
    }

    const handleSelectSerialNo = (serial: Serial) => {
        setSelectedSerials((prev) => {
            const isSelected = prev.some(
                (selected) =>
                    selected.batch_no === serial.batch_no &&
                    selected.mfg_date === serial.mfg_date &&
                    selected.exp_date === serial.exp_date &&
                    selected.serial_no === serial.serial_no
            )

            if (isSelected) {
                return prev.filter(
                    (selected) =>
                        selected.batch_no !== serial.batch_no ||
                        selected.mfg_date !== serial.mfg_date ||
                        selected.exp_date !== serial.exp_date ||
                        selected.serial_no !== serial.serial_no
                )
            } else {
                return [...prev, serial]
            }
        })
    }

    const handleSelectAll = () => {
        setSelectedSerials((prev) => {
            // Apply filters to the data before selection
            const filteredData = data.filter(applyFilters)

            const selectedSerials = uniqBy([...prev, ...filteredData], (serial) => `${serial.batch_no}-${serial.mfg_date}-${serial.exp_date}-${serial.serial_no}`)

            if (selectedSerials.length === prev.length) {
                return []
            }

            return selectedSerials
        })
    }

    return (
        <>
            <div className="flex flex-row items-end justify-between py-1">
                <p className="px-1">{intl.formatMessage({ id: "serial_no" })}</p>

                <p className="text-right">
                    {intl.formatMessage({ id: "selected" })} {intl.formatMessage({ id: "quantity" })}:{" "}
                    {selectedSerials.reduce((acc, item) => acc + parseFloat(item.unit_qty), 0)}
                </p>
            </div>
            <div className="flex flex-row items-end justify-between py-1">
                <Input
                    value={search}
                    prefix={<Search className="h-4 w-4 text-muted-foreground" />}
                    postfix={<X className="cursor-pointer text-muted-foreground transition-colors hover:text-foreground" onClick={() => setSearch("")} />}
                    placeholder={`${intl.formatMessage({ id: "search" })} ${intl.formatMessage({ id: "serial_no" })}`}
                    onChange={(e) => setSearch(e.target.value)}
                    className="flex-1"
                />
                <Button variant="link" className="flex h-fit items-center gap-2 px-1 py-0" onClick={() => handleSelectAll()}>
                    <span>{intl.formatMessage({ id: isAllSelected() ? "deselect_all" : "select_all" })}</span>
                </Button>
            </div>
            {filteredData.length > 0 ? (
                <ScrollArea className="m-0">
                    <div className="flex w-max flex-row justify-center gap-4 p-2 pl-1">
                        {filteredData.filter(applyFilters).map((serial, index) => {
                            const isSelected = (serial: Serial) => {
                                return selectedSerials.some(
                                    (selected) =>
                                        selected.batch_no === serial.batch_no &&
                                        selected.mfg_date === serial.mfg_date &&
                                        selected.exp_date === serial.exp_date &&
                                        selected.serial_no === serial.serial_no
                                )
                            }

                            return (
                                <div
                                    key={`${serial.batch_no}-${serial.mfg_date}-${serial.exp_date}-${serial.serial_no}`}
                                    className={cn(
                                        "flex flex-col rounded-sm border p-2 hover:bg-primary hover:outline hover:outline-1 hover:outline-offset-2 hover:outline-primary",
                                        isSelected(serial) && "bg-primary text-primary-foreground"
                                    )}
                                    onClick={() => handleSelectSerialNo(serial)}
                                >
                                    <p className="m-0 text-sm">
                                        {intl.formatMessage({ id: "batch_no" })}:{" "}
                                        <span className="font-bold">{serial.batch_no === "null" ? intl.formatMessage({ id: "no_batch_no" }) : serial.batch_no}</span>
                                    </p>
                                    <p className="m-0 text-sm">
                                        {intl.formatMessage({ id: "mfg_date" })}:{" "}
                                        <span className="font-bold">{serial.mfg_date === "null" ? intl.formatMessage({ id: "no_mfg_date" }) : serial.mfg_date}</span>
                                    </p>
                                    <p className="m-0 text-sm">
                                        {intl.formatMessage({ id: "exp_date" })}:{" "}
                                        <span className="font-bold">{serial.exp_date === "null" ? intl.formatMessage({ id: "no_exp_date" }) : serial.exp_date}</span>
                                    </p>
                                    <p className="m-0 text-sm">
                                        {intl.formatMessage({ id: "serial_no" })}:{" "}
                                        <span className="font-bold">{serial.serial_no === "null" ? intl.formatMessage({ id: "no_serial_no" }) : serial.serial_no}</span>
                                    </p>
                                    <p className="m-0 text-sm">
                                        {intl.formatMessage({ id: "qty" })}: <span className="font-bold">{parseFloat(serial.unit_qty).toFixed(0)}</span>
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                    <ScrollBar orientation="horizontal" />
                </ScrollArea>
            ) : (
                <span className="rounded-sm bg-gray-400 bg-opacity-15 p-2 text-center">{intl.formatMessage({ id: "no_results" })}</span>
            )}
        </>
    )
}

export default SerialNosSelect
